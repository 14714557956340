import { GradientColorOption, GradientTextureOption, IGradientConfig } from "@soleran/contracts";

export const DEFAULT_GRADIENT_CONFIG: IGradientConfig = {
	top: {
		colorOption: 3,
		customColor: "#e0e0e0"
	},
	bottom: {
		colorOption: GradientColorOption.None
	},
	texture: GradientTextureOption.None
};